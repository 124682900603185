<template>
    <div class="overlay">
        <div class="innerframe">
            <button @click="doCancel" class="close-button">×</button>
            <div class="innerframe-header">
                <div class="title" v-if="title">
                    {{ title }}
                </div>
            </div>
            <div class="content">
                <MopSortSelector
                    :columns="columns"
                    :orders="orders"
                    :id="myid + '_sortselector'"
                    @changed="changed"
                />
                <div class="control-box">
                    <div>
                        <button @click="doCancel">キャンセル</button>
                    </div>
                    <div>
                        <button @click.once="doOk" :disabled="alwaysOk">
                            OK
                        </button>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MopSortSelector from "@/components/MopSortSelector.vue";

export default {
    props: {
        title: String,
        columns: Object,
        id: String,
        orders: { type: Array, default: new Array() }
    },
    components: {
        MopSortSelector
    },
    data: function() {
        return {
            result: Array.from(this.orders),
            alwaysOk: false,
            myid: this.id
        };
    },
    methods: {
        doOk() {
            this.alwaysOk = true;
            this.$emit("decide", this.result);
        },
        doCancel: function() {
            this.$emit("cancel");
        },
        changed: function(val) {
            this.result = val;
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "mopsortselectormodal" + this.$store.state.idseq;
        }
    }
};
</script>

<style scoped>
.innerframe-header {
    display: grid;
    min-height: 1rem;
    grid-template-columns: 1fr 1fr;
}
.innerframe-header .title {
    justify-self: start;
}
.confirm-panel {
    position: relative;
    min-width: 450px;
    padding: 0;
}
.control-box {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}
</style>
