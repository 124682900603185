<template>
    <div class="mop-sort-selector">
        <div class="columnlist-frame">
            <transition-group name="flip-list" tag="ul" class="columnlist">
                <li v-for="(item, ix) in columnList" :key="item.key">
                    <div style="display:inline-block;">
                        <span v-if="item.flag">{{ ix + 1 }}</span>
                    </div>
                    <div>
                        <OpCheckbox
                            :label="item.label"
                            v-model="item.flag"
                            :name="'sortcolumn' + ix"
                            :id="id + '_' + item.key"
                            @input="changedSelect(item)"
                        />
                    </div>
                    <div v-if="item.flag">
                        <OpRadioGroup
                            v-model="item.desc"
                            v-slot="{ item: cho }"
                            :choices="[
                                { key: 0, name: 'A..Z' },
                                { key: 1, name: 'Z..A' }
                            ]"
                            :itemKey="cho => cho.key"
                            @input="changed"
                        >
                            {{ cho.name }}
                        </OpRadioGroup>
                    </div>
                    <div v-if="item.flag">
                        <button
                            class="upbutton"
                            @click="upSelected(item, ix)"
                            :disabled="ix < 1"
                        >
                            ↑
                        </button>
                    </div>
                    <div v-if="item.flag">
                        <button
                            class="downbutton"
                            @click="downSelected(item, ix)"
                            :disabled="getLastSelectedIndex() == ix"
                        >
                            ↓
                        </button>
                    </div>
                </li>
            </transition-group>
        </div>
    </div>
</template>

<script>
import OpRadioGroup from "@/components/OpRadioGroup.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";

export default {
    name: "MopSortSelector",
    components: {
        OpRadioGroup,
        OpCheckbox
    },
    props: {
        name: String,
        label: String,
        columns: Object,
        orders: Array,
        id: String
    },
    data: function() {
        return {
            columnList: this.initColumnList(),
            myid: this.id
        };
    },
    watch: {
        inputModel: function(val) {
            this.dataModel = val;
        }
    },
    methods: {
        inputChange: function() {
            this.$emit("input", this.dataModel);
        },

        initColumnList: function() {
            let ret = [];
            let ix = 0;
            let inimx = 0;

            if (this.orders) {
                inimx = this.orders.length;
            }

            let forder = inimx;
            for (let c in this.columns) {
                let flag = false;
                let desc = 0;
                let order = Number.MAX_VALUE;
                for (let i = 0; i < inimx; i++) {
                    let o = this.orders[i];
                    if (o.key == c) {
                        order = i;
                        flag = true;
                        desc = o.desc;
                        break;
                    }
                }
                if (!flag) {
                    order = ++forder;
                }

                ret.push({
                    key: c,
                    label: this.columns[c],
                    desc: desc,
                    index: ++ix,
                    flag: flag,
                    order: order
                });
            }

            return ret.sort(this.sortOrder);
        },
        sortOrder: function(item1, item2) {
            let ret = 0;
            let f1 = item1.flag;
            let f2 = item2.flag;
            if (f1 && !f2) {
                ret = -1;
            } else if (!f1 && f2) {
                ret = 1;
            } else if (f1 && f2) {
                let o1 = item1.order;
                let o2 = item2.order;
                if (o1 < o2) {
                    ret = -1;
                } else if (o1 > o2) {
                    ret = 1;
                }
            } else {
                let ix1 = item1.index;
                let ix2 = item2.index;
                if (ix1 < ix2) {
                    ret = -1;
                } else if (ix1 > ix2) {
                    ret = 1;
                }
            }
            return ret;
        },
        changedSelect: function(item) {
            console.log("★★ : " + item.key + " : " + item.index);
            if (item.flag) {
                item.order = this.getLastSelectedIndex() + 1;
            }
            this.columnList.sort(this.sortOrder);
            this.changed();
        },
        upSelected: function(item1, ix) {
            if (ix > 0) {
                let item0 = this.columnList[ix - 1];
                item0.order++;
                item1.order--;
                // this.columnList.splice(ix - 1, 2, item1, item0);
                this.columnList.sort(this.sortOrder);
                this.changed();
            }
        },
        downSelected: function(item0, ix) {
            let mx = this.getLastSelectedIndex();
            if (ix < mx) {
                let item1 = this.columnList[ix + 1];
                item0.order++;
                item1.order--;
                // this.columnList.splice(ix, 2, item1, item0);
                this.columnList.sort(this.sortOrder);
                this.changed();
            }
        },
        getLastSelectedIndex: function() {
            let ret = -1;
            let mx = this.columnList.length;
            for (let i = 0; i < mx; i++) {
                let item = this.columnList[i];
                if (!item.flag) {
                    break;
                }
                ret = i;
            }
            return ret;
        },
        changed: function() {
            let ret = [];
            for (let a of this.columnList) {
                if (a.flag) {
                    ret.push({ key: a.key, desc: a.desc });
                }
            }
            this.$emit("changed", ret);
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>
<style scoped>
.columnlist {
    list-style-type: none;
    list-style-position: initial;
    text-align: left;
}
.columnlist li {
    display: grid;
    grid-template-columns: 50px 200px 150px 50px 50px;
    align-items: center;
    height: 3rem;
}
.columnlist div {
    display: inline-block;
}
.flip-list-move {
    transition: transform 0.5s;
}
</style>
