<template>
    <div :class="['form-field', { disabled: disabled }]">
        <div class="title" v-if="label">{{ label }}</div>
        <div class="restrict" v-if="required && !hideheader">
            <div
                v-if="required"
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
            >
                必
            </div>
        </div>
        <div class="form-field-input radio-group">
            <div
                class="radio-item"
                v-for="(item, ix) in choices"
                :key="getItemKey(item)"
            >
                <input
                    type="radio"
                    :value="getItemKey(item)"
                    @change="inputChange"
                    @click="radioClick(item)"
                    @keyup.n="radioClick(item)"
                    v-model="dataModel"
                    :name="name"
                    :id="myid + ix"
                    :disabled="disabled || readonly"
                />
                <label
                    :for="myid + ix"
                    :class="
                        disabled || (readonly && dataModel != getItemKey(item))
                            ? 'disabled'
                            : ''
                    "
                >
                    <slot :item="item">{{ getItemIndicate(item) }}</slot>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpRadioGroup",
    model: {
        prop: "radioModel",
        event: "input"
    },
    props: {
        radioModel: [String, Number],
        choices: Array,
        name: String,
        required: Boolean,
        label: String,
        id: String,
        disabled: Boolean,
        readonly: Boolean,
        itemKey: Function,
        itemIndicate: Function,
        hideheader: Boolean
    },
    data: function() {
        return {
            dataModel: this.radioModel,
            validflag: false,
            myid: this.id
        };
    },
    methods: {
        inputChange: function() {
            this.$emit("input", this.dataModel);
        },
        // sanitizeModel: function(model) {
        //     let ret = null;
        //     for (const item in this.choices) {
        //         console.log(
        //             "★★ model : " +
        //                 model +
        //                 " == item.key : " +
        //                 this.getItemKey(item)
        //         );
        //         if (model == this.getItemKey(item)) {
        //             ret = model;
        //             break;
        //         }
        //     }
        //     return ret;
        // },
        _checkRequired() {
            let ret =
                !this.required ||
                (this.dataModel != null && this.dataModel != undefined);
            this.validflag = ret;
            return ret;
        },
        radioClick(item) {
            if (!this.required && this.dataModel == this.getItemKey(item)) {
                this.dataModel = null;
                this.$emit("input", null);
            }
        },
        getItemKey: function(item) {
            if (this.itemKey) {
                return this.itemKey(item);
            }
            return item;
        },
        getItemIndicate: function(item) {
            if (this.itemIndicate) {
                return this.itemIndicate(item);
            }
            return item;
        }
    },
    watch: {
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        required: function() {
            this._checkRequired();
        },
        radioModel: function(val) {
            this.dataModel = val;
        }
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        }
    },
    mounted: function() {
        this._checkRequired();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>

<style scoped>
input {
    position: absolute;
    left: -99999px;
}
</style>
