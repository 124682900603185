<template>
    <div class="form-field">
        <label class="title" v-if="label" :for="myid">{{ label }}</label>
        <div>
            <div class="form-field-input op-search-textfield">
                <input
                    class="op-textfield-inner"
                    type="text"
                    @change="inputChange"
                    v-model="textModel"
                    :id="myid"
                    ref="input"
                    @keyup.enter="search"
                />
                <button
                    type="button"
                    class="op-textfield-clear-button"
                    @click="cleartext"
                    tabindex="-1"
                >
                    ×
                </button>
                <button
                    type="button"
                    class="op-textfield-search-button"
                    tabindex="-1"
                    @click="search"
                >
                    🔍
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpSearchTextField",
    model: {
        prop: "inputModel",
        event: "input"
    },
    data: function() {
        return {
            textModel: this.inputModel,
            myid: this.id,
            myBlur: null
        };
    },
    props: {
        inputModel: String,
        name: String,
        id: String,
        label: String
    },
    watch: {
        // inputModel: function(val) {
        //     this.textModel = val;
        // }
    },
    methods: {
        inputChange: function() {
            this.$emit("input", this.textModel);
        },
        focus: function() {
            this.$refs.input.focus();
        },
        search: function() {
            this.$emit("changed-query", this.textModel);
        },
        cleartext: function() {
            this.textModel = null;
            this.$emit("input", this.textModel);
            this.$emit("changed-query", this.textModel);
        }
    },
    computed: {},
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>
