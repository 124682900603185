<template>
    <button class="actionButton" @click="doClick">
        <div class="mark">
            <IconBase><IconSort /></IconBase>
        </div>
        <div class="txt">並べ替え</div>
    </button>
</template>

<script>
import MopButtonMixin from "@/components/buttons/MopButtonMixin.vue";
import IconSort from "@/components/icons/IconSort.vue";

export default {
    name: "MopSortButton",
    mixins: [MopButtonMixin],
    components: {
        IconSort
    }
};
</script>
<style scoped>
.actionButton {
    margin: 0ex;
    font-size: 1rem;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
}
.mark {
    margin: 0.25rem 0 0 0;
    padding: 0;
    display: inline-block;
}
.txt {
    font-size: 0.5rem;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    text-align: center;
}
</style>
